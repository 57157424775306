import React, { useState } from "react"
import { graphql } from "gatsby"
import Parser from "html-react-parser"
import styled from "styled-components"
import { Typography, Grid } from "@material-ui/core"
import Checkbox from "@material-ui/core/Checkbox"
import FormGroup from "@material-ui/core/FormGroup"
import FormControlLabel from "@material-ui/core/FormControlLabel"

// import app components
import { BackgroundImage, Edges, PostPreview, Spacer } from "components"

import WaveAltIcon from "icons/flipped-wave-sm.svg"
import { colors } from "theme"
import { sortBy } from "utils"

export default function NewsTemplate(props) {
  const {
    data: {
      page: {
        templateNews: {
          newsFields: {
            hero: { image, headline, text }
          }
        }
      },
      news,
      newsCategories,
      reviews,
      reviewCategories
    }
  } = props

  const [activeNewsCategories, setActiveNewsCategories] = useState(
    newsCategories.nodes
  )
  const [activeReviewCategories, setActiveReviewCategories] = useState(
    reviewCategories.nodes
  )

  const handleChangeNewsCategory = cat => {
    if (cat === "all") {
      if (activeNewsCategories.length === newsCategories?.nodes.length) {
        setActiveNewsCategories([])
      } else {
        setActiveNewsCategories(newsCategories?.nodes)
      }

      return
    }

    let newCategories = [...activeNewsCategories]

    const index = activeNewsCategories.findIndex(
      o => o.databaseId === cat.databaseId
    )

    if (index > -1) {
      newCategories.splice(index, 1)
    } else {
      newCategories.push(cat)
    }

    setActiveNewsCategories(newCategories)
  }

  const handleChangeReviewCategory = cat => {
    if (cat === "all") {
      if (activeReviewCategories.length === reviewCategories?.nodes.length) {
        setActiveReviewCategories([])
      } else {
        setActiveReviewCategories(reviewCategories?.nodes)
      }

      return
    }

    let newCategories = [...activeReviewCategories]

    const index = activeReviewCategories.findIndex(
      o => o.databaseId === cat.databaseId
    )

    if (index > -1) {
      newCategories.splice(index, 1)
    } else {
      newCategories.push(cat)
    }

    setActiveReviewCategories(newCategories)
  }

  let posts = []

  if (news?.nodes) {
    const allNews = news.nodes

    const activeNewsCategoryIds = activeNewsCategories.map(o => o.databaseId)

    const filteredNews =
      activeNewsCategories.length !== newsCategories?.nodes.length
        ? allNews.filter(o =>
            o.categories.nodes.find(p =>
              activeNewsCategoryIds.includes(p.databaseId)
            )
          )
        : allNews

    posts = filteredNews
  }

  if (reviews?.nodes) {
    const allReviews = reviews.nodes

    const activReviewsCategoryIds = activeReviewCategories.map(
      o => o.databaseId
    )

    const filtered =
      activeReviewCategories.length !== reviewCategories?.nodes.length
        ? allReviews.filter(o =>
            o.reviewCategories.nodes.find(p =>
              activReviewsCategoryIds.includes(p.databaseId)
            )
          )
        : allReviews

    posts = posts.concat(filtered)
  }

  sortBy(posts, "date")

  return (
    <>
      <HeroContainer>
        <HeroOverlay />
        <NewsMediaHero>
          <Edges size="cont">
            <Grid container>
              <Grid item md={2}></Grid>
              <Grid item md={8}>
                {headline && <Typography variant="h1" children={headline} />}
                <Spacer mb={25} />
                <CatSelect>
                  <Typography variant="body1" children={"CATEGORIES"} />
                  <FormGroup row>
                    <StyledFormLabel
                      control={
                        <StyledCheckbox
                          color="primary"
                          checked={
                            activeNewsCategories.length ===
                            newsCategories?.nodes.length
                          }
                          onChange={() => handleChangeNewsCategory("all")}
                        />
                      }
                      label={"All"}
                    />
                    {newsCategories?.nodes.map(o => {
                      return (
                        <StyledFormLabel
                          key={o.databaseId}
                          control={
                            <StyledCheckbox
                              color="primary"
                              checked={
                                !!activeNewsCategories.find(
                                  p => p.databaseId === o.databaseId
                                )
                              }
                              onChange={() => handleChangeNewsCategory(o)}
                            />
                          }
                          label={o.name}
                        />
                      )
                    })}
                  </FormGroup>
                  <Spacer mb={{ xs: 15, md: 30 }} />
                  <Typography
                    variant="body1"
                    children={"MEDIA PRODUCT REVIEWS"}
                  />
                  <FormGroup row>
                    <StyledFormLabel
                      control={
                        <StyledCheckbox
                          color="primary"
                          checked={
                            activeReviewCategories.length ===
                            reviewCategories?.nodes.length
                          }
                          onChange={() => handleChangeReviewCategory("all")}
                        />
                      }
                      label={"All"}
                    />
                    {reviewCategories?.nodes.map(o => {
                      return (
                        <StyledFormLabel
                          key={o.databaseId}
                          control={
                            <StyledCheckbox
                              color="primary"
                              checked={
                                !!activeReviewCategories.find(
                                  p => p.databaseId === o.databaseId
                                )
                              }
                              onChange={() => handleChangeReviewCategory(o)}
                            />
                          }
                          label={o.name}
                        />
                      )
                    })}
                  </FormGroup>
                </CatSelect>
              </Grid>
            </Grid>
          </Edges>
        </NewsMediaHero>
        {image && (
          <ImageContainer>
            <BackgroundImage image={image} />
          </ImageContainer>
        )}
      </HeroContainer>

      <FeaturedContainer>
        <Edges size="lg">
          <WaveContainer>
            <WaveAltIcon />
          </WaveContainer>
        </Edges>

        <Edges size="md">
          <Spacer pt={{ xs: 40 }} pb={{ xs: 60 }}>
            {text && (
              <Spacer mb={{ xs: 25 }}>
                <Typography
                  variant="body1"
                  children={Parser(text)}
                  color="inherit"
                />
              </Spacer>
            )}
          </Spacer>
        </Edges>

        <NewsFeedContainer>
          {posts &&
            posts.map(o => {
              return (
                <PostPreview
                  date={o?.date}
                  headline={o?.title}
                  image={o?.acf?.image}
                  key={o?.id}
                  url={o?.uri}
                />
              )
            })}
        </NewsFeedContainer>
      </FeaturedContainer>
    </>
  )
}

const HeroContainer = styled.div`
  position: relative;
  height: 410px;
  padding: ${({ border }) => (border ? "20px" : 0)};

  @media (min-width: 768px) {
    height: 430px;
  }

  &:before {
    content: "";
    position: absolute;
    z-index: 1;
    left: 0;
    bottom: 0;
    width: 100%;
    height: 300px;
    background: radial-gradient(
      33.94% 50% at 77.5% 120.75%,
      #000000 0%,
      rgba(0, 0, 0, 0) 100%
    );
    background: linear-gradient(
      28.09deg,
      #000000 14.29%,
      rgba(0, 0, 0, 0) 39.6%
    );
  }
`

const HeroOverlay = styled.div`
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(50px);
  position: absolute;
  width: 100%;
  height: 380px;
  bottom: 0px;
  left: 0px;
  z-index: 1;

  @media (min-width: 768px) {
    height: 400px;
  }
`

const CatSelect = styled.div``

const NewsFeedContainer = styled.div``

const FeaturedContainer = styled.div`
  background: radial-gradient(
    69.12% 69.29% at 12.66% -27.53%,
    #000000 41.89%,
    #252525 100%
  );
  color: ${colors.text.light};
  position: relative;
`

const WaveContainer = styled.div`
  position: absolute;
  z-index: 1;
  left: 0px;
  top: 0px;

  svg {
    max-width: 100%;
    width: 100%;
  }
`

const NewsMediaHero = styled.div`
  align-items: center;
  background: linear-gradient(28.09deg, #000000 14.29%, rgba(0, 0, 0, 0) 39.6%);
  backdrop-filter: blur(12px);
  bottom: 0;
  color: white;
  display: flex;
  height: 400px;
  left: 0;
  margin-top: 70px;
  padding-top: 15px;
  position: absolute;
  width: 100%;
  z-index: 1;

  h1 {
    margin-bottom: 12px;
  }

  @media (min-width: 600px) {
    margin-top: 0px;
    padding-top: 0px;
  }
`

const ImageContainer = styled.div`
  position: relative;
  height: 100%;
`

const StyledFormLabel = styled(FormControlLabel)`
  width: calc(50% - 5px);

  @media (min-width: 768px) {
    width: calc(33% - 5px);
  }

  @media (min-width: 900px) {
    width: calc(25% - 5px);
  }
`

const StyledCheckbox = styled(Checkbox)`
  svg {
    path {
      fill: ${colors.tertiary};
    }
  }
`

export const CollectionQuery = graphql`query newsTemplate($id: String!) {
  page: wpPage(id: {eq: $id}) {
    templateNews {
      newsFields {
        hero {
          text
          headline
          image {
            altText
            localFile {
              childImageSharp {
                gatsbyImageData(quality: 100, layout: FULL_WIDTH)
              }
            }
          }
        }
      }
    }
  }
  news: allWpPost {
    nodes {
      id
      title
      uri
      date
      categories {
        nodes {
          name
          databaseId
        }
      }
      acf {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  newsCategories: allWpCategory {
    nodes {
      databaseId
      name
    }
  }
  reviews: allWpReview {
    nodes {
      id
      title
      uri
      date
      reviewCategories {
        nodes {
          name
          databaseId
        }
      }
      acf {
        text
        image {
          altText
          localFile {
            childImageSharp {
              gatsbyImageData(width: 600, layout: CONSTRAINED)
            }
          }
        }
      }
    }
  }
  reviewCategories: allWpReviewCategory {
    nodes {
      databaseId
      name
    }
  }
}
`
